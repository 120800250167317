body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #171717;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Inter var',
    'Courier New', monospace;
}

.App {
  height: 100vh;
}

.form-container {
  /* position: absolute; */
  position: relative;
  bottom: 110px;
  margin-left: auto;
  margin-right: auto;
  /* bottom: 20px;
  left: 0;
  right: 0; */
  max-width: 600px;
  background: rgba(255, 255, 255, 0.1);
  min-height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 100;
}

.userInputForm {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.inputBox {
  flex: 8;
  width: 400px;
  height: 45px;
  margin-right: 15px;
  padding-left: 10px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 0 16px 0 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  transition: 0.4s ease;
}

.inputBox::placeholder {
  color: #ffffff;
  opacity: 0.7;
}

.inputBox:focus {
  border-radius: 16px 0 16px 0;
}

.inputBox:hover {
  border-radius: 16px 0 16px 0;
}

.formButton {
  flex: 1;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0 16px 0 16px;
  height: 67px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
  color: rgb(212, 211, 211);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
  transition: 0.4s ease;
}

.formButton:hover {
  border-radius: 16px 0 16px 0;
}

.speechb-container {
  position: absolute;
  top: 50px;
  right: 2%;
  padding: 10px;

  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.mic {
  transform: scale(1.5);
  transition: 0.4s ease;
}

.speechb {
  background-color: red;
  padding: 10px 15px 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  width: 25em;
  margin: 0 0 10px;
}
.speechb-owner {
  font-weight: bold;
  color: black;
  margin: 6px 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.historyButton{
  float: right;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0 16px 0 16px;
  height: 67px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
  color: rgb(212, 211, 211);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
}
